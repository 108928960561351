'use strict';
define([], function () {
    return {
        templateUrl: 'app/modules/seospider/exam/analysis/components/crawl-session-detail/crawl-session-detail.html',
        bindings: {
            session: '<',
        },
        controller: ['$window', '$scope', '$mdMedia', function ($window, $scope, $mdMedia) {
            var vm = $scope;
            vm.$mdMedia = $mdMedia;

            this.$onChanges = function () {
                vm.session = this.session;
            };

            vm.formatDate = function (date, options) {
                if (!date) {
                    return;
                }
                var localLang = $window.localStorage.getItem('NG_TRANSLATE_LANG_KEY');
                return new Date(date).toLocaleDateString(localLang, options);
            };
        }]
    };
});
