/**
 * @ngdoc overview
 * @name screenshot.service:ScreenshotService
 *
 * @description
 * Aggregate External function for Screenshot Module
 */
'use strict';
define(['app'], function (app) {
    app.service('ScreenshotService', ScreenshotService);
    ScreenshotService.$inject = ['$window', '$q', '__env', '$http'];

    /**
     * Pagespeed Service
     * @namespace ScreenshotService
     */
    function ScreenshotService($window, $q, __env, $http) {
        var apiBaseUrl = __env.apiGateway + '/api/' + __env.apiVersion + '/';
        var apiPath = apiBaseUrl + __env.screenshotAPI;

        this.getScreenshot = function (url, device) {
            var deferred = $q.defer();

            if (!$window.fetch) {
                return $q.resolve();
            }

            $http.get(`${apiPath}/generate?url=${encodeURIComponent(url)}&device=${device}`)
                .then(function (res) {
                    $window.console.log('res', res);
                    if (res.data && res.data.screenshot) {
                        deferred.resolve(res.data.screenshot);
                        return;
                    }
                    deferred.reject();
                })
                .catch(function (err) {
                    $window.console.log(err);
                    deferred.resolve();
                });

            return deferred.promise;
        };
    }
});
