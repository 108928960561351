'use strict';
define([], function () {
    function Controller($scope, $rootScope, $stateParams, $mdMedia, FavIconService, $state, SeospiderService, $interval, $timeout, $localStorage) {
        var vm = $scope;
        var loadPromise;
        vm.isBroken = false;
        vm.$mdMedia = $mdMedia;
        vm.getFavIcon = FavIconService.get;
        vm.isLoading = true;
        vm.sessionId = $stateParams.sessionId;
        vm.projectId = $stateParams.projectId;
        vm.examsScore = {
            ok: 0,
            warning: 0,
            error: 0
        };
        vm.resources = [];
        vm.updateSession = updateSession;
        var nextLoad = function (delay) {
            cancelNextLoad();
            loadPromise = $timeout(getUpdateSessionForTime, delay);
        };

        function updateSession() {
            var params = {
                id: vm.projectId
            };
            SeospiderService.update(params)
                .then(function (res) {
                    resetGraph();
                    if (!res || !res.data || !res.data.crawlerSessionId) {
                        $state.go('app.seospider.list');
                        return;
                    }
                    vm.session.resourcesCount = 0;
                    vm.sessionId = res.data.crawlerSessionId;
                    getUpdateSessionForTime();
                })
                .catch(function (err) {
                    $rootScope.$broadcast("stopCrawling", {
                        sessionId: vm.sessionId
                    });
                });
        }

        function getUpdateSessionForTime() {
            vm.examsScore = {
                ok: 0,
                warning: 0,
                error: 0
            };

            SeospiderService.getSessionsUpdate(vm.projectId, vm.sessionId, new Date().toISOString())
                .then(function (res) {
                    vm.session = Object.assign(res.data, res.data.session);
                    var isSessionInRunning = (res.data && vm.session && vm.session.status === SeospiderService.getStates().RUNNING) || vm.session.status === SeospiderService.getStates().STOPPING;
                    var isSessionFinished = res.data && vm.session && vm.session.status === SeospiderService.getStates().FINISHED;

                    if (isSessionInRunning) {
                        vm.isLoading = true;
                        pushResourceMissing(vm.session.resources);
                        nextLoad(3000);
                        return;
                    }

                    if (isSessionFinished) {
                        vm.isLoading = false;
                        vm.inCrawling = false;
                        vm.inStopCrawl = false;
                        vm.crawlFinished = true;
                        $rootScope.$broadcast("crawlFinished", {
                            sessionId: vm.sessionId
                        });
                        getReportForScore();
                    }

                    vm.isLoading = false;
                })
                .catch(function (err) {
                    $rootScope.$broadcast("stopCrawling", {
                        sessionId: vm.sessionId
                    });
                    console.log(err);
                });
        }

        function pushResourceMissing(resources) {
            if (resources && resources.length) {
                angular.forEach(resources, function (resource) {
                    if (!containsObject(resource, vm.resources)) {
                        resource.animation = true;
                        vm.resources.push(resource);
                    }
                });

                var interval = $interval(function () {
                    vm.resources.forEach(function (resource) {
                        resource.animation = false;
                    });
                    $interval.cancel(interval);
                }, 3000);
            }
        }

        function containsObject(obj, list) {
            return list.some(function (elem) {
                return elem.url + '-' + elem.status === obj.url + '-' + obj.status;
            });
        }

        function setGlobalScore(report) {
            if (!report) {
                return;
            }
            var score = report.base.score;
            score += report.content.score;
            score += report.structure.score;
            score += report.duplicated.score;
            score += report.social.score;
            score = score / 5;

            vm.categories = {
                base: report.base,
                content: report.content,
                structure: report.structure,
                duplicated: report.duplicated,
                social: report.social
            };

            vm.lastScore = score;
        }

        var updateDashboard = function (sessionId, projectId) {
            vm.sessionId = parseInt(sessionId);
            vm.projectId = parseInt(projectId);
            $state.go('app.seospider.exam.analysis.report.overview', {
                sessionId: sessionId,
                projectId: projectId,
            });
        };

        vm.formatDate = function (date, options) {
            if (!date) {
                return;
            }
            var localLang = localStorage.getItem('NG_TRANSLATE_LANG_KEY');
            return new Date(date).toLocaleDateString(localLang, options);
        };

        function resetGraph() {
            vm.resources = [];
            vm.session = {
                resources: [],
                codes: [],
                codesByDepth: [],
                links: [],
                codesByMinute: [],
                resourcesCount: 0,
                session: {},
                urls: []
            };

            vm.examsScore = {
                ok: 0,
                warning: 0,
                error: 0
            };
        }

        var getData = function (shouldGetReport = false) {
            var eventParams = {
                sessionId: vm.sessionId
            };
            SeospiderService.getSessions(vm.projectId, vm.sessionId)
                .then(function (res) {
                    vm.session = res.data;

                    // Check if session is older than 5 minutes and has less than 3 resources
                    var createdAtDate = new Date(vm.session.createdAt);
                    var currentDate = new Date();
                    var diffInMinutes = (currentDate - createdAtDate) / (1000 * 60);

                    if (diffInMinutes > 5 && vm.session.resources.length < 3) {
                        vm.isBroken = true;
                    }

                    $localStorage.currentSessionResourcesCount = vm.session.resourcesCount;
                    if (vm.session.status === SeospiderService.getStates().RUNNING || vm.session.status === SeospiderService.getStates().STOPPING) {
                        $rootScope.$broadcast("inCrawling", eventParams);
                        getUpdateSessionForTime(vm.sessionId);
                        return;
                    }
                    if (vm.session.status === SeospiderService.getStates().FINISHED) {
                        vm.isLoading = false;
                        vm.inCrawling = false;
                        vm.inStopCrawl = false;
                        vm.crawlFinished = true;
                        $rootScope.$broadcast("crawlFinished", eventParams);
                    }
                    if (vm.session && vm.session.resources && vm.session.resources.length) {
                        vm.resources = vm.session.resources;
                    }
                    if (!vm.session.categories || shouldGetReport) {
                        getReportForScore();
                    }
                })
                .catch(function (err) {
                    $rootScope.$broadcast("stopCrawling", {
                        sessionId: vm.sessionId
                    });
                    cancelNextLoad();
                });
        };

        function getReportForScore() {
            SeospiderService.report({
                jobId: vm.sessionId,
                lang: 'en'
            }).then(function (res) {
                getExamPerforming(res);
                setGlobalScore(res);
                getData();
            });
        }

        function getExamPerforming(report) {
            if (!report) {
                return;
            }
            if (report.base && report.base.exams) {
                var fieldBaseExams = Object.keys(report.base.exams);
                setExamPerforming(report.base.exams, fieldBaseExams);
            }
            if (report.content && report.content.exams) {
                var fieldContentExam = Object.keys(report.content.exams);
                setExamPerforming(report.content.exams, fieldContentExam);
            }
            if (report.duplicated && report.duplicated.exams) {
                var fieldDuplicatedExam = Object.keys(report.duplicated.exams);
                setExamPerforming(report.duplicated.exams, fieldDuplicatedExam);
            }
            if (report.social && report.social.exams) {
                var fieldSocialExam = Object.keys(report.social.exams);
                setExamPerforming(report.social.exams, fieldSocialExam);
            }
            if (report.structure && report.structure.exams) {
                var fieldStructureExam = Object.keys(report.structure.exams);
                setExamPerforming(report.structure.exams, fieldStructureExam);
            }
        }

        function setExamPerforming(typeExamSection, listOfExamPerType) {
            angular.forEach(listOfExamPerType, function (exam) {
                if (typeExamSection[exam].score >= 80) {
                    vm.examsScore.ok += 1;
                }
                if (typeExamSection[exam].score < 80 && typeExamSection[exam].score >= 60) {
                    vm.examsScore.warning += 1;
                }
                if (typeExamSection[exam].score < 60) {
                    vm.examsScore.error += 1;
                }
            });
        }

        var cancelNextLoad = function () {
            $timeout.cancel(loadPromise);
        };

        vm.$on("inCrawling", function (evt, action) {
            vm.lastScore = null;
        });
        vm.$on("stopCrawling", function (evt, action) {
            vm.session.status = SeospiderService.getStates().STOPPING;
        });

        vm.ipAddresses = '0.0.0.0';
        SeospiderService
            .getIpAddress()
            .then(function (res) {
                if (res && res.length > 0) {
                    vm.ipAddresses = res.join(', ');
                }
            });

        vm.cleanUrl = function (url) {
            if (!url) {
                return;
            }
            url = url.replace('https://', '');
            url = url.replace('http://', '');
            url = url.replace('www.', '');
            url = url.replace(/^www\./i, '');
            url = url.replace(/\/$/i, '');
            return url.trim();
        };

        updateDashboard(vm.sessionId, vm.projectId);
        getData(true);
    }

    Controller.$inject = ['$scope', '$rootScope', '$stateParams', '$mdMedia', 'FavIconService', '$state', 'SeospiderService', '$interval', '$timeout', '$localStorage'];
    return Controller;
});
